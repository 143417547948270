import * as React from "react";
import Layout from "../Components/Layout";
import PolicyPL from "./privacy-and-cookies-policy.pl";
import PolicyEN from "./privacy-and-cookies-policy.en";
import Image from "../Components/ImageQueries/LargeFeaturesImages";

const WhatWeDo = () => {
    const query = new URLSearchParams(typeof window !== `undefined` && window.location.search);
    const language = query.get("lang") || "en";

  return (
    <Layout customFoot>
      <div className="relative">
        <Image
          imageName="semi-circle.png"
          className="privacy-img absolute xl:top-[128px] xl:left-[90%] xl:w-[390px] lg:w-[350px] md:left-[80%] w-0"
        />
        <section className="text-[16px] mt-[106px] max-w-[1440px] xl:mx-auto relative">
          <div className="lg:ml-[160px] lg:px-0 px-8 max-w-[733px]">
            [{" "}
            {language === "en" ? (
              "EN"
            ) : (
              <a href="?lang=en" title="EN">
                EN
              </a>
            )}
            {" | "}
            {language === "pl" ? (
              "PL"
            ) : (
              <a href="?lang=pl" title="PL">
                PL
              </a>
            )}
            ]{language === "pl" ? <PolicyPL /> : <PolicyEN />}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default WhatWeDo;
